<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <form
      action=""
      class="horizontal-form"
      @submit.prevent="addingUser"
    >
      <div class="row">
        <div class="col form-group">
          <label for="type_compte">Type de compte</label>
          <select
            v-select="{placeholder: 'Selectionnez le type de compte'}"
            v-model="type_compte"
            name="type_compte"
            id="type_compte"
            class="form-control"
          >
            <option value="" />
            <option
              v-for="(type, index) in filtredTypeCompte"
              :value="type.uid"
              :key="index"
            >
              {{ type.libelle }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="fullname">Nom complet</label>
          <input
            class="form-control"
            type="text"
            name="fullname"
            id="fullname"
            v-model="fullname"
          >
        </div>
        <div class="col form-group">
          <label for="login">Email</label>
          <input
            class="form-control"
            type="text"
            name="login"
            id="login"
            v-model="login"
          >
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="telephone">Téléphone</label>
          <input
            class="form-control"
            type="text"
            name="telephone"
            id="telephone"
            v-model="telephone"
          >
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="address">Adresse</label>
          <textarea
            name="address"
            id="address"
            rows="5"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <input
            type="submit"
            class="btn btn-primary"
            value="Ajouter"
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import navbar from '../../components/navbar.vue'
import {Select} from '../../directive/select'
import { REGISTRATION } from '../../graphql/user'
import { TYPE_ADMIN, TYPE_SUPER_ADMIN, TYPE_VETERIANIRE,  TYPE_LABORATOIRE, TYPE_TECHNICIEN_IMAGERIE, TYPE_ASSISTANT_VETERINAIRE, TYPE_ASSISTANT_VETERINAIRE_MAJOR } from '../../constants/app'
export default {
    directives: {Select},
    components: {navbar},
    data(){
        return {
          navbarItems: [
            {
                libelle: 'Utilisateurs',
                route: 'users'
            },
            {
              libelle: 'Ajout'
            }
          ],
          pageIcon: 'la-user',
          pageTitle: 'Ajout utilisateur',
          pageDescription: 'Ajout d\'un nouvel utilisateur',
          type_compte: null,
          fullname: '',
          login: '',
          telephone: '',
          address: ''
        }
    },
    methods: {
        addingUser(){
            this.$apollo.mutate({
                mutation: REGISTRATION,
                variables: {
					fullname: this.fullname,
                    email: this.login,
                    telephone: this.telephone,
                    typeCompte :this.type_compte,
                    address: this.address
				},
                update: (store, { data  }) => {
                    console.log(data)
				},
            }).then((data) => {
                console.log(data)
                this.$router.push({name: 'users'})
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            me: 'auth/me',
            typeComptes: 'auth/typeComptes',
            pays: 'auth/pays'
        }),
        AllowedCreatedType (){
            if(this.me !== null){
                let myTypeCompte = this.typeComptes.find(item => item.uid === this.me.typeCompte)
                switch(myTypeCompte.code){
                    case TYPE_SUPER_ADMIN: return [...this.typeComptes].filter(item => this.allowedCreatedTypeForSuperAdmin.includes(item.code) )
                    case TYPE_ADMIN: return [...this.typeComptes].filter(item => this.allowedCreatedTypeForAdmin.includes(item.code) )
                    default: return []
                }
            }
            return  []
        },
        allowedCreatedTypeForSuperAdmin(){
          return [
            TYPE_ADMIN,
            TYPE_VETERIANIRE,
            TYPE_LABORATOIRE,
            TYPE_ASSISTANT_VETERINAIRE,
            TYPE_TECHNICIEN_IMAGERIE, 
            TYPE_ASSISTANT_VETERINAIRE_MAJOR
          ]
        },
        allowedCreatedTypeForAdmin(){
          return [
            TYPE_VETERIANIRE,
            TYPE_LABORATOIRE,
            TYPE_ASSISTANT_VETERINAIRE,
            TYPE_TECHNICIEN_IMAGERIE, 
            TYPE_ASSISTANT_VETERINAIRE_MAJOR
          ]
        },
        filtredTypeCompte(){
            return [...this.AllowedCreatedType].sort((a,b) => a.libelle.localeCompare(b.libelle))
        }
    }
}
</script>

<style scoped>

</style>
